<template>
  <Page type="full">
    <div v-html="$store.getters.getPageContent('saannot')"/>
    <button class="center" @click="$router.push('/friends')">
      Kateissa olevat ystävät
    </button>
  </Page>
</template>
<script>
import Page from '@/components/Page'

export default {
  components: {
    Page
  }
}
</script>

<style lang="scss" scoped>
button {
  margin: 10px auto;
}
</style>
