<template>
  <Page type="full">
    <h1>Kadoksissa olevat ystävät</h1>
    <div>
      <div v-for="friend in $store.getters.getFriends" :key="friend.slug" class="friend">
        <img :src="friend.solved ? $store.getters.getImage(friend.round) : $store.getters.getImage(friend.silhouette)" />
        <p>{{ friend.name }}</p>
      </div>
    </div>
    <button v-if="$store.state.returnPath" @click="next">
      Jatka peliä
    </button>
    <button v-else class="center" @click="$router.push('/search')">
      Etsi QR-koodeja
    </button>
  </Page>
</template>
<script>
import Page from '@/components/Page'

export default {
  components: {
    Page
  },
  methods: {
    next () {
      this.$router.push(this.$store.state.returnPath)
      this.$store.commit('setReturnPath', undefined)
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  margin: 10px auto;
}
.friend{
  width: 50%;
  float: left;
  height: 65vw;
  @media (min-width: 640px) {
    height: 420px;
  }
  img {
    width: 80%;
  }
}
</style>
