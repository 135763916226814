<template>
  <div id="splash" class="gradbg">
    <div class="container">
      <img class="logo" :src="logo" alt="Zoolandia - Fantin kadonneet ystävät" />
      <img id="ryhmakuva" :src="ryhma" alt="Ryhmäkuva" />
      <button class="center" @click="$router.push('/score')">Aloita</button>
    </div>
  </div>
</template>
<script>
import logo from '@/assets/Zoolandia-logo-ja-teksti.png'
import ryhma from '@/assets/ryhmakuva.svg'

export default {
  components: {
  },
  data () {
    return {
      logo,
      ryhma
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
#splash {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 2rem);
  }
  #ryhmakuva {
    margin-left: -10%;
    margin-right: -12%;
    max-width: unset;
    max-height: 50vh;
  }
}
</style>
