<template>
  <div class="wheel-of-fortune">
    <canvas id="wheel" ref="wheel" width="600" height="700"/>
  </div>
</template>

<script>
import wheel from '@/assets/wheel.svg'
import arrow from '@/assets/arrow.svg'
import AudioPlayer from '@/AudioPlayer'
import sound from '@/assets/audio/Tick-DeepFrozenApps-397275646.mp3'

export default {
  name: 'WheelOfFortune',
  data () {
    return {
      loadCounter: 0,
      wheelImage: null,
      arrowImage: null,
      iconImages: [],
      wheelCanvas: null,
      rotation: 0,
      target: 0,
      tick: 0,
      arrowUp: false,
      audio: null
    }
  },
  props: {
    images: Array
  },
  methods: {
    loadAssets () {
      console.debug('load')
      this.wheelImage = this.loadWrapper(wheel)
      this.arrowImage = this.loadWrapper(arrow)
      this.images.forEach(img => {
        const image = (typeof img === 'object') ? img : { url: img, size: 80 }
        this.iconImages.push({
          image: this.loadWrapper(image.url),
          size: image.size
        })
      })
    },
    loadWrapper (src) {
      const img = new Image()
      this.loadCounter++
      img.addEventListener('load', this.loadHandler)
      img.src = src
      return img
    },
    loadHandler () {
      if (--this.loadCounter < 1) {
        this.createWheel()
      }
    },
    createWheel () {
      console.debug('draw')
      this.wheelCanvas = document.createElement('canvas')
      this.wheelCanvas.width = 600
      this.wheelCanvas.height = 600
      const ctx = this.wheelCanvas.getContext('2d')
      ctx.translate(300, 300)
      ctx.drawImage(this.wheelImage, -300, -300, 600, 600)
      ctx.rotate(0 - 22.5 * Math.PI / 180)
      this.iconImages.forEach(img => {
        const halfSize = img.size / 2
        ctx.drawImage(img.image, 0 - halfSize, -190 - halfSize, img.size, img.size)
        ctx.rotate(0 - 45 * Math.PI / 180)
      })
      this.drawWheel()
    },
    drawWheel () {
      const canvas = this.$refs.wheel
      const ctx = canvas.getContext('2d')
      ctx.save()
      ctx.clearRect(0, 0, 600, 800)
      ctx.translate(300, 400)
      ctx.rotate(this.rotation * Math.PI / 180)
      ctx.drawImage(this.wheelCanvas, -300, -300, 600, 600)
      ctx.rotate(0 - this.rotation * Math.PI / 180)
      ctx.translate(0, -360)
      ctx.rotate(this.arrow * Math.PI / 180)
      ctx.drawImage(this.arrowImage, -60, -12, 120, 120)
      ctx.restore()
    },
    spin (result = null) {
      if (this.target === this.rotation) {
        // Spin the wheel.
        while (this.rotation >= 360) {
          // Reduce rotation value by a full rotation until value is under 360. This keeps absolute values under control.
          this.rotation -= 360
        }
        // Set a target that is 1000 plus random 0-359 degrees greater than current rotation.
        this.target = 1000 + Math.floor(Math.random() * 360)
        // If result is set, make sure that's the outcome. (Cheat function)
        if (result !== null) {
          const diff = result - this.getResult(this.target)
          this.target += (diff * 45)
        }
        // Start the stepping. 25 FPS.
        setTimeout(this.step, 40)
      }
    },
    step () {
      // Calculate angle remaining to target.
      const remaining = this.target - this.rotation
      // Set default step size (speed).
      let step = 8
      if (remaining < 380) {
        // If less than 380 degrees remaining, start slowing down wheel.
        step = Math.ceil(remaining / (380 / step))
      }
      // Rotate the wheel by current step.
      this.rotation += step
      // See if there's a pin hitting the arrow.
      const pin = Math.floor((this.rotation + 2) % 22.5)
      if (pin <= 8) { // Pin is hitting.
        // Set tick value.
        this.tick = pin
        // Mark arrow as moving up.
        this.arrowUp = true
      } else if (this.tick > 0) { // Pin moved past.
        // Is arrow moving up?
        if (this.arrowUp) {
          // Move arrow to top position, because in higher speeds steps are skipped.
          this.tick = 8
          // Set arrow as moving down.
          this.arrowUp = false

          const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
          // Play tick sound.
          if (!isSafari) {
            this.audio.play()
          }
        }
        // Move the arrow back towards center.
        this.tick -= (this.tick > 3) ? 2 : 1
      }
      // Check if this have stopped moving.
      if (remaining < 1 && (pin <= 8 || this.tick < 1)) {
        // Stop the interval from running.
        // Emit result event.
        this.$emit('result', this.getResult(this.target))
      } else {
        setTimeout(this.step, 40)
      }
      this.drawWheel()
    },
    getResult (rotation) {
      // Calculate where the arrow points. This compensates for the pins, so adjust if changed.
      return Math.floor(((rotation - 7) % 360) / 45)
    }
  },
  computed: {
    arrow () {
      // Calculate the arrows visual rotation based on tick value.
      return 0 - (this.tick * 4)
    }
  },
  mounted () {
    // Randomize starting position.
    this.rotation = Math.floor(Math.random() * 360)
    this.target = this.rotation
    // Load audio file into multi channel player.
    this.audio = new AudioPlayer(sound)
    this.loadAssets()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wheel-of-fortune {
  width: 300px;
  height: 350px;
  max-width: 100%;
  margin: auto;
  overflow: hidden;

  canvas {
    width: 300px;
    max-width: 100%;
  }
}
</style>
