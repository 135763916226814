<template>
  <Page type="full">
    <div>
      <div v-for="friend in $store.getters.getFriends" :key="friend.slug" class="friend">
        <img :src="friend.solved ? $store.getters.getImage(friend.round) : $store.getters.getImage(friend.silhouette)"
             @click="cheat(friend.slug)"/>
        <p @click="$router.push('/qr/' + friend.slug)">{{ friend.name }}</p>
      </div>
    </div>
    <button class="center" @click="$router.go(-1)">
      Takaisin
    </button>
  </Page>
</template>
<script>
import Page from '@/components/Page'

export default {
  components: {
    Page
  },
  methods: {
    cheat (slug) {
      console.debug(slug)
      this.$store.commit('setFriend', slug)
      this.$store.dispatch('updateProgress', { solved: true })
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  margin: 10px auto;
}

.friend {
  width: 50%;
  float: left;
  height: 65vw;
  @media (min-width: 640px) {
    height: 420px;
  }
  img {
    width: 80%;
  }
}
</style>
