<template>
  <Page :image="$store.getters.getPageImage('start')" v-if="$store.getters.getScore < 1" id="start">
    <div v-html="$store.getters.getPageContent('start')"/>
    <button class="center" @click="$router.push('/rules')">
      Katso säännöt
    </button>
  </Page>
  <Page :image="48" v-else-if="$store.getters.getScore < 10 && $store.getters.getScore >= 1" id="score">
    <h1>Fantti</h1>
    <div>
      Jo
      <span v-if="$store.getters.getScore === 1">
          yksi ystävä
        </span>
      <span v-else>
          <span v-if="$store.getters.getScore === 2">
            kaksi
          </span>
          <span v-else-if="$store.getters.getScore === 3">
            kolme
          </span>
          <span v-else-if="$store.getters.getScore === 4">
            neljä
          </span>
          <span v-else-if="$store.getters.getScore === 5">
            viisi
          </span>
          <span v-else-if="$store.getters.getScore === 6">
            kuusi
          </span>
          <span v-else-if="$store.getters.getScore === 7">
            seitsemän
          </span>
          <span v-else-if="$store.getters.getScore === 8">
            kahdeksan
          </span>
          <span v-else-if="$store.getters.getScore === 9">
            yhdeksän
          </span>
          <span v-else-if="$store.getters.getScore === 10">
            kymmenen
          </span>
          ystävää
        </span>
      kyydissä. Jatketaan peliä!
    </div>

    <button class="center" @click="$router.push('/friends')">
      Jatka
    </button>
  </Page>
  <Page :image="$store.getters.getPageImage('end')" v-else-if="$store.state.code" id="end" type="big">
    <div v-html="$store.getters.getPageContent('end')"/>
    <p>{{ $store.state.code.date }} {{ $store.state.code.time }}</p>
    <p class="number">{{ $store.state.code.code }}</p>
    <button v-if="!$store.state.code.winner" @click="reset" id="reset">Aloita alusta</button>
  </Page>
</template>

<script>

import Page from '@/components/Page'

export default {
  name: 'Score',
  components: {
    Page
  },
  methods: {
    reset () {
      if (confirm('Oletko varma että haluat aloita uudestaan?')) {
        console.debug('reset')
        this.$store.commit('resetGame')
        this.$router.push('/')
      }
    }
  },
  beforeMount () {
    if (!this.$store.state.code && this.$store.getters.getScore === 10) {
      this.$store.dispatch('getCode')
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  margin: 10px auto;
  display: block;
}
#end {
  h1 {
    color: var(--color-light);
  }
  p {
    color: var(--color-heading);
    font-size: 12px;
  }
  .number {
    background: var(--color-light);
    color: var(--color-gray);
    padding: 8px;
    max-width: 289px;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
  }
  a {
    font-size: 12px;
    color: var(--color-heading);
  }
  #reset {
    background: none;
    color: var(--color-heading);
  }
}
</style>
