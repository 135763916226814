import axios from 'axios'

const baseUrl = (process.env.NODE_ENV === 'production') ? 'https://zoo.qrpeli.fi/?rest_route=' : 'https://zoolandia.test/?rest_route='

const getConfig = () => {
  const config = {}
  if (global.wpApiSettings !== undefined) {
    config.headers = { 'X-WP-Nonce': global.wpApiSettings.nonce }
  }
  return config
}

export const fetchPages = async () => {
  return await fetchUrl(baseUrl + '/wp/v2/pages&_fields=slug,title,content,featured_media')
}
export const fetchMedia = async () => {
  return await fetchUrl(baseUrl + '/zoolandia/v1/media')
}
export const fetchFriends = async () => {
  return await fetchUrl(baseUrl + '/zoolandia/v1/friends')
}
export const fetchCode = async (nonce) => {
  const response = await axios.get(baseUrl + '/zoolandia/v1/get_code' + '&nonce=' + nonce, getConfig())
  return response.data
}

export const fetchUrl = async (url, perPage = 100) => {
  const data = []
  let page = 0
  const sign = (url.includes('?')) ? '&' : '?'
  do {
    page++
    const response = await axios.get(url + sign + 'per_page=' + perPage + '&page=' + page, getConfig())
    if (response.status === 200) {
      for (const i in response.data) {
        data.push(response.data[i])
      }
    }
  } while (data.length > 0 && data.length % perPage === 0)
  return data
}
