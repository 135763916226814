import { createRouter, createWebHistory } from 'vue-router'
import Splash from '../views/Splash'
import Friend from '@/views/Friend'
import Friends from '@/views/Friends'
import Score from '@/views/Score'
import Rules from '@/views/Rules'
import Search from '@/views/Search'
import Cheat from '@/views/Cheat'
import Wheel from '@/views/Wheel'

const routes = [
  {
    path: '/',
    name: 'Splash',
    component: Splash
  },
  {
    path: '/rules',
    name: 'Rules',
    component: Rules
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/score',
    name: 'Score',
    component: Score
  },
  {
    path: '/qr/:id',
    name: 'Friend',
    component: Friend
  },
  {
    path: '/cheatDebug',
    name: 'Cheat',
    component: Cheat
  },
  {
    path: '/wheelDebug',
    name: 'Wheel',
    component: Wheel
  },
  {
    path: '/friends',
    name: 'Friends',
    component: Friends
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

export default router
