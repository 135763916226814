<template>
  <div>
    <p>
      {{ text }}
    </p>
    <div>
      <button v-for="(option, index) in options" :key="index" @click="select(index)">{{ option }}</button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: String,
    options: Array,
    answer: Number
  },
  methods: {
    select (i) {
      if (i === this.answer) {
        this.$emit('result', true)
      } else {
        this.$emit('result', false)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
button {
  position: relative;
  width: 100%;
  margin-bottom: .75rem;
  text-align: left;
  padding-left: 3rem;
  height: 42px;
  z-index: 1;
  &::after {
    display: block;
    position: absolute;
    content: 'x';
    background-color: var(--color-primary);
    color: var(--color-light);
    top: 4px;
    left: 4px;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    z-index: 2;
    text-align: center;
    line-height: 34px;
    font-size: 16px;
  }
  @for $i from 1 through 9 {
    &:nth-child(#{$i})::after {
      content: '#{$i}';
    }
  }
}

</style>
