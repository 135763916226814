<template>
  <div class="slot-machine">
    <canvas ref="my-canvas" width="320" height="280"></canvas>
  </div>
</template>

<script>
import 'yuki-createjs'
import rollerStill from '../assets/slotmachine_roller_still.png'
import rollerMoving from '../assets/slotmachine_roller_moving.png'
import rollerTop from '../assets/slotmachine_roller_top.png'
/* eslint-disable */

const preload = new createjs.LoadQueue()
const self = this

var slotroller = {
  roller: null,
  cb: null,
  init: function (rollerStill, rollerMoving, rollerTop) {
    var rollerBgStill = new createjs.Container()
    var rollerImage = new createjs.Bitmap(rollerStill)
    var rollerImage2 = new createjs.Bitmap(rollerStill)
    rollerImage2.y = 750
    rollerBgStill.addChild(rollerImage)
    rollerBgStill.addChild(rollerImage2)
    var rollerBgMoving = new createjs.Container()
    var rollerImage = new createjs.Bitmap(rollerMoving)
    var rollerImage2 = new createjs.Bitmap(rollerMoving)
    rollerImage2.y = 750
    rollerBgMoving.addChild(rollerImage)
    rollerBgMoving.addChild(rollerImage2)
    rollerBgMoving.alpha = 0
    var rollerContainer = new createjs.Container()
    var rollerTop = new createjs.Bitmap(rollerTop)
    this.roller = new createjs.Container()
    this.roller.addChild(rollerBgStill)
    this.roller.addChild(rollerBgMoving)
    var rollermask = new createjs.Shape()
    rollermask.graphics.beginFill(createjs.Graphics.getRGB(0, 0, 0)).drawRect(0, 0, 150, 300).endFill()
    this.roller.mask = rollermask
    rollerContainer.addChild(this.roller)
    rollerContainer.addChild(rollerTop)
    this.roller.y = -2 * 750 + 375

    return rollerContainer
  },
  start: function ( rolls, offset, cb ) {
    this.roller.getChildAt(1).alpha = 1;
    this.roller.y = -2 * 750 + 375;
    var startPos = this.roller.y;
    if (rolls == 2) {
      createjs.Tween.get(this.roller, {loop: false})
        .to({y: startPos + 750}, 500, createjs.Ease.getPowIn(2))
        .to({y: startPos}, 1, createjs.Ease.linear())
        .to({y: startPos + 750}, 500, createjs.Ease.linear())
        .to({y: startPos}, 1, createjs.Ease.linear())
        .to({y: startPos + 750 + 150 * offset}, 500, createjs.Ease.getPowOut(2))
        .call(cb, [this.roller, false])
    }
    if (rolls == 3) {
      createjs.Tween.get(this.roller, {loop: false})
        .to({y: startPos + 750}, 500, createjs.Ease.getPowIn(2))
        .to({y: startPos}, 1, createjs.Ease.linear())
        .to({y: startPos + 750}, 500, createjs.Ease.linear())
        .to({y: startPos}, 1, createjs.Ease.linear())
        .to({y: startPos + 750}, 500, createjs.Ease.linear())
        .to({y: startPos}, 1, createjs.Ease.linear())
        .to({y: startPos + 750 + 150 * offset}, 500, createjs.Ease.getPowOut(2))
        .call(cb, [this.roller, false])
    }
    if (rolls == 4) {
      createjs.Tween.get(this.roller, {loop: false})
        .to({y: startPos + 750}, 500, createjs.Ease.getPowIn(2))
        .to({y: startPos}, 1, createjs.Ease.linear())
        .to({y: startPos + 750}, 500, createjs.Ease.linear())
        .to({y: startPos}, 1, createjs.Ease.linear())
        .to({y: startPos + 750}, 500, createjs.Ease.linear())
        .to({y: startPos}, 1, createjs.Ease.linear())
        .to({y: startPos + 750}, 500, createjs.Ease.linear())
        .to({y: startPos}, 1, createjs.Ease.linear())
        .to({y: startPos + 750 + 150 * offset}, 500, createjs.Ease.getPowOut(2))
        .call(cb, [this.roller, false])
        .wait(500)
        .call(cb, [this.roller, true])
    }
  }
  // soundEffect("spin");
}

export default {
  components: {},
  name: 'Slotmachine',
  data: function () {
    return {
      queue: null,
      stage: null,
      luck: 0,
      result: false,
      roller1: null,
      roller2: null,
      roller3: null,
      isRolling: false,
      offsets: [[0,-2,-1],[2,2,-1],[1,2,0],[1,-2,-1],[1,1,0],[2,2,2],[0,1,0],[-1,-1,-1],[1,2,1]]
    }
  },
  props: {
    hahmo: null
  },
  methods: {
    start: function (force=null) {
      if (!this.isRolling) {
        this.result = false;
        this.luck = Math.round( Math.random()*(this.offsets.length-1) )

        // luck 5 = kikka
        // luck 7 = keijo
        // lets make sure you cannot win wrong character
        while( (this.hahmo == 'keijo' && this.luck == 5) || (this.hahmo == 'kikka' && this.luck == 7) ) {
          this.luck = Math.round( Math.random()*(this.offsets.length-1) )
        }

        if( force == 'keijo' ) {
          this.luck = 7
        }
        if( force == 'kikka' ) {
          this.luck = 5
        }

        this.roller1.start( 2, this.offsets[this.luck][0], this.stopped );
        this.roller2.start( 3, this.offsets[this.luck][1], this.stopped );
        this.roller3.start( 4, this.offsets[this.luck][2], this.stopped );
        this.isRolling = true
      }
    },
    stopped: function ( roller, isLast ) {
      roller.getChildAt(1).alpha = 0 // hide blurred
      if (isLast) {
        if( this.luck == 7 || this.luck == 5 ){
          this.result = true;
        }
        this.isRolling = false
        this.$emit('result', this.result)
      }
    },

    handleFileComplete: function (event) {
      this.roller1 = Object.create(slotroller)
      this.roller2 = Object.create(slotroller)
      this.roller3 = Object.create(slotroller)

      let still = preload.getResult('roller-still')
      let moving = preload.getResult('roller-moving')
      let top = preload.getResult('roller-top')

      var roller1 = this.roller1.init(still, moving, top, this)
      roller1.scaleX = roller1.scaleY =  0.65
      roller1.x = 10
      roller1.y = 60
      this.stage.addChild( roller1 )

      var roller2 = this.roller2.init(still, moving, top, this)
      roller2.scaleX = roller2.scaleY = 0.65
      roller2.x = 110
      roller2.y = 60
      this.stage.addChild( roller2 )

      var roller3 = this.roller3.init(still, moving, top, this)
      roller3.scaleX = roller3.scaleY = 0.65
      roller3.x = 212
      roller3.y = 60
      this.stage.addChild( roller3 )
      this.stage.update();
    }
  },
  mounted () {
    this.stage = new createjs.Stage(this.$refs['my-canvas'])
    // createjs.Ticker.framerate = 60;
    createjs.Ticker.addEventListener('tick', this.stage)

    let assetList = [
      {src: rollerMoving, id: "roller-moving"},
      {src: rollerStill, id: "roller-still"},
      {src: rollerTop, id: "roller-top"},
    ]

    preload.addEventListener('complete', (e) => { this.handleFileComplete(e) })
    preload.loadManifest(assetList)
  }
}
</script>

<style>
</style>
