import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { throttle } from 'lodash'
import Vue3TouchEvents from 'vue3-touch-events'

// Initialize state from localStorage before passing it to Vue.
store.commit('initialiseState', localStorage.getItem('state'))

createApp(App).use(store).use(router).use(Vue3TouchEvents).mount('#app')

store.subscribe(throttle((mutation, state) => {
  if (mutation.type !== 'initialiseState') {
    const stateJson = JSON.stringify({ ...state, ui: undefined })
    // Store the state object as a JSON string
    localStorage.setItem('state', stateJson)
  }
}, 1000))

const init = () => {
  document.title = 'Zoolandia - kadonneet ystävät'
  store.dispatch('getPages')
  store.dispatch('getFriends')
  store.dispatch('getMedia')
  store.dispatch('disable')
  if (store.state.nonce === undefined) {
    console.debug('Initializing Game')
    store.commit('setReturnPath', window.location.pathname)
    store.commit('resetGame')
    router.push('/')
  }
}
init()
