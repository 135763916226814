export default class AudioPlayer {
  constructor (src, channels = 8) {
    this.channels = []
    this.cursor = 0
    for (let i = 0; i < channels; i++) {
      this.channels.push(new Audio(src))
    }
  }

  play () {
    this.channels[this.cursor++].play()
    if (this.cursor >= this.channels.length) {
      this.cursor = 0
    }
  }
}
