<template>
  <WheelOfFortune ref="wheel" :images="getImages"/>
  <button @click="spin">Spin</button>
</template>
<script>

import WheelOfFortune from '@/components/WheelOfFortune'
import star from '@/assets/wheel_star.svg'
import iceCream from '@/assets/wheel_icecream.svg'
import clover from '@/assets/wheel_clover.svg'

export default {
  components: {
    WheelOfFortune
  },
  methods: {
    spin () {
      this.$refs.wheel.spin()
    }
  },
  computed: {
    getImages () {
      return [
        star,
        star,
        iceCream,
        star,
        star,
        clover,
        iceCream,
        clover
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  margin: 10px auto;
}
</style>
